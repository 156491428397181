<template>
  <b-modal
    id="modal-create-account"
    :visible="show"
    title="Tạo tài khoản"
    :size="singleSelect ? 'lg' : 'md'"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <template v-if="singleSelect">
          <b-row>
            <!-- Họ tên -->
            <b-col>
              <basic-input
                :value.sync="form.fullName"
                name="name"
                label="Họ tên"
                placeholder="Nhập họ tên"
                disabled
              />
            </b-col>

            <!-- Số điện thoại -->
            <b-col>
              <basic-input
                :value.sync="form.phoneNumber"
                name="phoneNumber"
                label="Số điện thoại"
                disabled
                placeholder="Nhập số điện thoại"
              />
            </b-col>
          </b-row>
          <b-row>
            <!-- Ngày sinh -->
            <b-col>
              <basic-input
                :value.sync="form.birthDate"
                name="dateOfBirth"
                label="Ngày sinh"
                placeholder="Nhập ngày sinh"
                disabled
              />
            </b-col>

            <!-- Giới tính -->
            <b-col>
              <basic-input
                :value.sync="form.sex"
                name="gender"
                label="Giới tính"
                placeholder="Nhập giới tính"
                disabled
              />
            </b-col>
          </b-row>
          <b-row>
            <!-- Phân loại bệnh -->
            <b-col>
              <basic-input
                :value.sync="form.diseaseType"
                name="type"
                label="Phân loại bệnh"
                placeholder="Nhập phân loại bệnh"
                disabled
              />
            </b-col>

            <!-- Năm phát hiện bệnh -->
            <b-col>
              <basic-input
                :value.sync="form.diseaseYear"
                name="year"
                label="Năm phát hiện bệnh"
                placeholder="Nhập năm phát hiện bệnh"
                disabled
              />
            </b-col>
          </b-row>
          <b-row>
            <!-- Gói mua -->
            <b-col>
              <basic-input
                :value.sync="form.packageName"
                name="package"
                label="Gói mua"
                placeholder="Nhập gói mua"
                disabled
              />
            </b-col>

            <!-- Ngày mua -->
            <b-col>
              <basic-input
                :value.sync="form.boughtDate"
                name="boughtDate"
                label="Ngày mua"
                placeholder="Nhập ngày mua"
                disabled
              />
            </b-col>
          </b-row>
          <b-row>
            <!-- Mã giới thiệu -->
            <b-col cols="6">
              <basic-input
                :value.sync="form.referralCode"
                name="referralCode"
                label="Mã giới thiệu"
                placeholder="Nhập Mã giới thiệu"
                disabled
              />
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <div>Số khách hàng đã chọn: {{ selectedItems.length }}</div>
        </template>
        <div class="d-flex justify-content-end align-items-center">
          <b-button class="btn mr-2" type="button" @click="resetModal">
            Hủy
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Kích hoạt
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalCreateAccount',
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
    customer: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      form: {},
    };
  },
  computed: {
    singleSelect() {
      return this.selectedItems.length < 2;
    },
  },
  mounted() {
    this.form = {
      ...this.customer,
      birthDate: this.convertTimestampToDate(this.customer.birthDate),
      boughtDate: this.convertTimestampToDate(this.customer.boughtDate),
    };
  },
  methods: {
    resetModal() {
      this.form = {};
      this.$emit('reset');
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },
    async handleSubmit() {
      try {
        this.loading = true;
        const payload = {
          ids: this.selectedItems.length
            ? this.selectedItems.map((el) => el.id)
            : [this.customer.id],
        };
        const { meta, error } = await this.$api.post(
          '/PackageAccountTransaction/CreateUserAccount',
          payload,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.showToastrMessage(
          `Tạo mới ${this.form.fullName} thành công!`,
          'success',
        );
        this.resetModal();
        this.$emit('create-success');
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
  },
};
</script>
<style lang=""></style>
